<template>
    <v-app>
        <div>
            <v-card @click="redirecttoViewPage(appointment._id)" elevation="0" style="border-radius: 8px; border: 1px solid #E0E0E0;">
                <div class="py-2 d-flex justify-space-between">
                    <div v-if="all_bookings_appointment">
                        <v-checkbox  class="checkbox-style" style="margin-top:7px;"></v-checkbox>
                    </div>
                    <div style="width: 40%;" >
                        <p class="ma-0 px-1 normaltext" align="left">Booking Id: {{appointment.appointment_reference_number}}</p>
                    </div>
                    <div style="width: 60%;" >
                        <p class="ma-0 px-1 normaltext" align="right">{{formattedDate}}, <span class="ma-0 normaltext">{{formattedTimeSlot}}</span></p>
                    </div>
                </div>

                <v-divider></v-divider>

                <div class="px-2 pt-2 pb-1 d-flex justify-space-between align-center">
                    <div class="d-flex justify-start align-center" >
                        <img style="height:25px;" src="https://s3iconimages.mymedicine.com.mm/male.svg">
                        <p class="ma-0 pl-2 subheading">{{appointment.booked_for_name}}</p>
                    </div>

                    <v-card-actions v-if="completed_status && appointment.prescriptionFlag">
                        <span class="view-pres-btn"  v-on:click.stop="redirectToViewPrescription(appointment.app_refrence_no)"><img src="https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/mybookingsPrescription.svg" style="padding-right:5px;"></span>
                    </v-card-actions>
                </div>

                <div class="px-2 pt-1 pb-2 d-flex justify-space-between align-center">
                    <div class="d-flex justify-space-betwen">
                        <div class="d-flex justify-start">
                            <div >
                                <img style="height:25px;" src="https://s3iconimages.mymedicine.com.mm/male.svg">
                            </div>

                            <div class="d-flex flex-column justify-center" >
                                <p class="ma-0 pl-2 subheading" style="text-align: left;">{{appointment.doctor_name}}</p>
                                <p class="ma-0 pl-2 normaltext" style="text-align: left;">{{appointment.specialization_name}}</p>
                            </div>
                        </div>
                    </div>

                    <v-card-actions>
                        <v-btn elevation="0" v-if="appointment.isLinkActive" class="ma-0 pa-0" style="background-color: #27AE60; color: #FFFFFF; min-width: 36px !important; height: 32px !important;" v-on:click.stop="redirectToCallpage(appointment._id)"><img src="https://s3iconimages.mymedicine.com.mm/join_call.svg"></v-btn>
                        <v-btn elevation="0" v-else class="ma-0 pa-0" style="color: white; background-color: #E0E0E0; font-weight: 400; min-width: 36px !important; height: 32px !important;"><img src="https://s3iconimages.mymedicine.com.mm/join_call.svg"></v-btn>
                    </v-card-actions>
                </div>
                <v-divider></v-divider>
                <div style="padding: 8px;" v-if="!completed_status">
                    <v-btn elevation="0" class="ma-0 pa-0" style="background-color: #1467BF; color: #FFFFFF;width: 50%;; text-transform: capitalize;" 
                    @click.stop="copyLinkDetails" :disabled="completed_status">
                        copy Link Details
                    </v-btn>
                    <v-chip v-if="isCopied" style="margin-left: 2px;" color="success">Copied</v-chip>
                </div>
            </v-card>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
export default {
    name : 'BookingCardDetails',
    props:['all_bookings_appointment','upcoming_appointments_list','appointment','join_button','upcoming_status','completed_status'],
    data(){
        return{
            show_header:true,
            formattedDate: '',
            formattedTimeSlot: '',
            currentCustomer: '',
            isCopied: false,
        }
    },
    mounted() {
        if(window.ma){
            this.currentCustomer = localStorage.getItem('customerToken');
        } else {
            this.currentCustomer = this.$cookies.get("customerToken");
        }
        this.formattedDate = new Date(this.appointment.date_of_appointment).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
        this.formattedTimeSlot = new Date(
          "1970-01-01T" + this.appointment.time_slot.split("-")[0] + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        }) +
        " - " +
        new Date(
          "1970-01-01T" + this.appointment.time_slot.split("-")[1] + "Z"
        ).toLocaleTimeString("en-US", {
          timeZone: "UTC",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
      });
    },
    methods:{
      redirecttoViewPage(booking_id) {
        this.$router.push({
            path: "/customer/viewAppointment/" + booking_id,
         });
      },
      redirectToCallpage(meetingId) {
        this.$router.push({
            path: '/customer/videocallTwilio/'+ meetingId,
        });
      },
      redirectToViewPrescription(view_prescription_id) {
        this.$router.push({
            path:"/customer/viewPrescription/"+view_prescription_id,
        });
      },
      async copyLinkDetails() {
        let data = {
            token: this.currentCustomer,
            typeOfUser: 'CUSTOMER',
            bookingId: this.appointment._id
        };
        let appointmentRoomLink = this.appointment.appointment_room_link + '/' + this.appointment._id;
        let details = `
            Booking Id: ${this.appointment.appointment_reference_number}
            Date: ${this.formattedDate}
            Time: ${this.formattedTimeSlot}
            Booked For: ${this.appointment.booked_for_name}
            Doctor: ${this.appointment.doctor_name}
            Specialization: ${this.appointment.specialization_name}
            Share Link: ${appointmentRoomLink}
            Share Pin: ${this.appointment.appointment_room_pin}
        `;
           await this.copyToClipboard(details);
        try {
            await axios.post(process.env.VUE_APP_BACKEND_URL + "/copyLinkLogEntry", data);
        } catch (error) {
            console.log('Error: ', error);
        }
    
    },
    async copyToClipboard(text) {
        if (navigator.clipboard && window.isSecureContext) {
                try {
                 await navigator.clipboard.writeText(text);
                 alert('Copied')

                this.isCopied = true;
            } catch (err) {
                console.warn("Clipboard API failed, using fallback:", err);
                this.fallbackCopy(text);
                }
            } else {
                this.fallbackCopy(text);
            }
    },
    fallbackCopy(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.setAttribute('readonly', true);
      textArea.setAttribute('contenteditable', true);

      document.body.appendChild(textArea);
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px"; // Hide off-screen
      textArea.select();

      try {
        const successful = document.execCommand("copy"); // Fallback for Safari
        if (successful) {
            this.isCopied = true;
        } else {
          console.error("Fallback copy failed.");
        }
      } catch (err) {
        console.error("Fallback copy failed:", err);
      }

      document.body.removeChild(textArea);
    },
    },
}
</script>

<style scoped>
.v-application--wrap {
  min-height: none !important;
}
.month-style{
    position:absolute;
    left:3%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.booking_style{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #828282;
}

.text-styling{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #828282;
    padding-top:15px;
    padding-left:25px;
    padding-bottom:25px;
}

.date-styling{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #828282;
    padding-top:15px;
    padding-left:5px;
}
.specialist-styling{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #828282;
    padding-top:18px;
    padding-left:20px;
}
.checkbox-style{
    padding-left:10px;
}

.name-styling{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #333333;
    padding-top:0px;
    padding-left:5px;
}
.view-styling{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    padding-top:18px;
    padding-left:5px;
}
.join-btn .v-btn__content {
    align-items: center;
    /* padding: 8px 24px; */
    background: #27AE60;
    color:#FFFFFF;
    border-radius: 8px;
    /* padding-right:10px; */
}

.headertext{
    font-size: 18px;
    font-weight: bold;
}

.heading{
    font-size: 14px;
    font-weight: bold;
}

.subheading{
    font-size: 14px;
}

.normaltext{
    font-size: 10px;
    font-weight: 400;
    color: #828282;
}
</style>

<style>
.checkbox-style .v-icon.v-icon{
    font-size: 15px !important;
}


</style>